
import React, {Component, RefObject} from 'react';
import SinaPic from './img/member/sina.png';
import ParsaPic from './img/member/parsa.jpg';
import ShawnPic from './img/member/shawn.png';
import MickPic from './img/member/mick.png';
import ZavoshPic from './img/member/zavosh.png';
import GuyPic from './img/member/guy.jpg'
import NielsPic from './img/member/niels.jpg'
import LucPic from './img/member/luc.jpg'

interface IProp {
    teamRefProp: RefObject<HTMLDivElement>;
}

class Team extends Component<IProp> {

    constructor(props: IProp){
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(

            <div ref={this.props.teamRefProp}>

                {/* Team section */}
                <section className="team-section spad">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Meet Our Team</h2>
                            <p>Our team can always help you with any of your
                                questions!</p>
                        </div>
                    </div>
                    <div className="team-members clearfix">

                        {/* Team member */}
                        <div className="member">
                            <div className="member-text">
                                <div className="member-img " style={{backgroundImage: `url(${ParsaPic})`}}></div>
                                <h2>Parsa Heidary Moghadam</h2>
                                <span>CEO & CTO</span>
                            </div>
                            <div className="member-social">
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*/}
                                <a href="https://www.linkedin.com/in/parsa-heidary-moghadam-6b4321155/"><i className="fa fa-linkedin"></i></a>
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*/}
                            </div>
                            <div className="member-info">
                                <div className="member-img mf " >
                                    <img src={ParsaPic}/>
                                </div>
                                <div className="member-meta">
                                    <h2>Parsa Heidary Moghadam</h2>
                                    <span>CEO/CTO</span>
                                </div>
                                <br/>
                                <ul>
                                    <li>Senior Software Engineer</li>
                                    <li>PhD of Computer Science</li>
                                    <li>Data Science Engineer / Deep Learning Engineer</li>
                                    <li>Protocol-level blockchain
                                        programmer</li>
                                </ul>
                            </div>
                        </div>
                        {/* Team member */}
                        <div className="member">
                            <div className="member-text">
                                <div className="member-img " style={{backgroundImage: `url(${GuyPic})`}}></div>
                                <h2>Guy Ackermans</h2>
                                <span>CEO & COO</span>
                            </div>
                            <div className="member-social">
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*/}
                                <a href="https://www.linkedin.com/in/guy-ackermans/"><i className="fa fa-linkedin"></i></a>
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*/}
                            </div>
                            <div className="member-info">
                                <div className="member-img mf " >
                                    <img src={GuyPic}/>
                                </div>
                                <div className="member-meta">
                                    <h2>Guy Ackermans</h2>
                                    <span>CEO/COO</span>
                                </div>
                                <br/>
                                <ul>
                                    <li>Entrepreneur</li>
                                    <li>Senior Software Engineer</li>
                                    <li>Master of Computer Science</li>
                                    <li>Data Enterprise Architect</li>
                                </ul>
                            </div>
                        </div>
                        <br/><br/><br/>

                        <div className="member">
                            <div className="member-text">
                                <div className="member-img " style={{backgroundImage: `url(${NielsPic})`}}></div>
                                <h2>Niels Drost</h2>
                                <span>Data Engineer</span>
                            </div>
                            <div className="member-social">
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*/}
                                <a href="https://www.linkedin.com/in/niels-drost-01948b8b/"><i className="fa fa-linkedin"></i></a>
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*/}
                            </div>
                            <div className="member-info">
                                <div className="member-img mf " >
                                    <img src={NielsPic}/>
                                </div>
                                <div className="member-meta">
                                    <h2>Niels Drost</h2>
                                    <span>Data Engineer</span>
                                </div>
                                <br/>
                                <ul>
                                    <li>Data Engineer</li>
                                    <li>Software Engineer</li>
                                    <li>Master of Computer Science</li>
                                    <li>Fullstack Developer</li>
                                </ul>
                            </div>
                        </div>

                        <div className="member">
                            <div className="member-text">
                                <div className="member-img " style={{backgroundImage: `url(${LucPic})`}}></div>
                                <h2>Luc Bakker</h2>
                                <span>Software Engineer</span>
                            </div>
                            <div className="member-social">
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*/}
                                <a href="https://www.linkedin.com/in/luc-bakker-4422aa94/"><i className="fa fa-linkedin"></i></a>
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*/}
                            </div>
                            <div className="member-info">
                                <div className="member-img mf " >
                                    <img src={LucPic}/>
                                </div>
                                <div className="member-meta">
                                    <h2>Luc Bakker</h2>
                                    <span>Software Engineer</span>
                                </div>
                                <br/>
                                <ul>
                                    <li>PhD in Biomedical Engineering</li>
                                    <li>Algorithm Designer</li>
                                    <li>Software Engineer</li>
                                </ul>
                            </div>
                        </div>

                        {/* Team member */}
                        <div className="member">
                            <div className="member-text">
                                <div className="member-img " style={{backgroundImage: `url(${SinaPic})`}}></div>
                                <h2>Sina Nazari</h2>
                                <span>Cyber-Security Advisor</span>
                            </div>
                            <div className="member-social">
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*/}
                                <a href="https://www.linkedin.com/in/sina-nazari/"><i className="fa fa-linkedin"></i></a>
                                {/*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*/}
                            </div>
                            <div className="member-info">
                                <div className="member-img mf " >
                                    <img src={SinaPic}/>
                                </div>
                                <div className="member-meta">
                                    <h2>Sina Nazari</h2>
                                    <span>Cyber-Security Advisor</span>
                                </div>
                                <br/>
                                <ul>
                                    <li>Blockchain entrepreneur</li>
                                    <li>Co-founder of Click Payments</li>
                                    <li>MBA graduate - Schulich School of Business</li>
                                    <li>Knows blockchain trends
                                        inside-out</li>
                                    <li>Researcher at the Blockchain Hub</li>
                                    <li>Cyber-Security expert and penetration tester</li>
                                </ul>
                            </div>
                        </div>
                        <br/><br/><br/>
                        {/* Team member */}
                        {/*<div className="member">*/}
                        {/*    <div className="member-text">*/}
                        {/*        <div className="member-img " style={{backgroundImage: `url(${ShawnPic})`}}></div>*/}
                        {/*        <h2>Shawn Rizvi</h2>*/}
                        {/*        <span>Risk Advisor</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="member-social">*/}
                        {/*        /!*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*!/*/}
                        {/*        <a href="https://www.linkedin.com/in/shawrizvi/"><i className="fa fa-linkedin"></i></a>*/}
                        {/*        /!*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*!/*/}
                        {/*    </div>*/}
                        {/*    <div className="member-info">*/}
                        {/*        <div className="member-img mf " >*/}
                        {/*            <img src={ShawnPic}/>*/}
                        {/*        </div>*/}
                        {/*        <div className="member-meta">*/}
                        {/*            <h2>Shawn Rizvi</h2>*/}
                        {/*            <span>Risk Advisor</span>*/}
                        {/*        </div>*/}
                        {/*        <br/>*/}
                        {/*        <ul>*/}
                        {/*            <li>Cyber-Risk</li>*/}
                        {/*            <li>Cyber-Security</li>*/}
                        {/*            <li>Global Privacy Laws</li>*/}
                        {/*            <li>AML/KYC Compliance</li>*/}
                        {/*            <li>Digital and Electronic Signature Laws</li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        {/* Team member */}
                        {/*<div className="member">*/}
                        {/*    <div className="member-text">*/}
                        {/*        <div className="member-img " style={{backgroundImage: `url(${MickPic})`}}></div>*/}
                        {/*        <h2>Mick Be</h2>*/}
                        {/*        <span>Business Advisor</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="member-social">*/}
                        {/*        /!*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*!/*/}
                        {/*        <a href="https://www.linkedin.com/in/mickbe/"><i className="fa fa-linkedin"></i></a>*/}
                        {/*        /!*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*!/*/}
                        {/*    </div>*/}
                        {/*    <div className="member-info">*/}
                        {/*        <div className="member-img mf " >*/}
                        {/*            <img src={MickPic}/>*/}
                        {/*        </div>*/}
                        {/*        <div className="member-meta">*/}
                        {/*            <h2>Mick Be</h2>*/}
                        {/*            <span>Business Advisor</span>*/}
                        {/*        </div>*/}
                        {/*        <br/>*/}
                        {/*        <ul>*/}
                        {/*            <li>CEO & Founder, IamI Authentications</li>*/}
                        {/*            <li>Business Mentor and Coach</li>*/}
                        {/*            <li>IDaaS (IS Software as a Service</li>*/}
                        {/*            <li>IT Strategy</li>*/}
                        {/*            <li>Product Strategy</li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        {/* Team member */}
                        {/*<div className="member">*/}
                        {/*    <div className="member-text">*/}
                        {/*        <div className="member-img " style={{backgroundImage: `url(${ZavoshPic})`}}></div>*/}
                        {/*        <h2>Zavosh Zaboliyan</h2>*/}
                        {/*        <span>Growth Advisor</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="member-social">*/}
                        {/*        /!*<a style={{cursor: "pointer"}} href=""><i className="fa fa-facebook"></i></a>*!/*/}
                        {/*        <a href="https://www.linkedin.com/in/zaboliyan/"><i className="fa fa-linkedin"></i></a>*/}
                        {/*        /!*<a style={{cursor: "pointer"}} href=""><i className="fa fa-twitter"></i></a>*!/*/}
                        {/*    </div>*/}
                        {/*    <div classNamessName="member-info">*/}
                        {/*        <div className="member-img mf " >*/}
                        {/*            <img src={ZavoshPic}/>*/}
                        {/*        </div>*/}
                        {/*        <div className="member-meta">*/}
                        {/*            <h2>Zavosh Zaboliyan</h2>*/}
                        {/*            <span>Growth Advisor</span>*/}
                        {/*        </div>*/}
                        {/*        <br/>*/}
                        {/*        <ul>*/}
                        {/*            <li>Serial Entrepreneur and Growth Manager</li>*/}
                        {/*            <li>Associate, Lozard Group</li>*/}
                        {/*            <li>Strategic Investments, Thinking North</li>*/}
                        {/*            <li>Executive Producer, Award-Winning Documentary Heroes Manufactured (2016)</li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                    </div>
                </section>
                {/* Team section */}
            </div>
        );
    }
}

export default Team;