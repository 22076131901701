
import React, {Component} from 'react';
import tech from './img/tech.png';
import handshake from './img/handshake.png';

interface IProp {
    activeProp: Number;
}

class Hero extends Component<IProp> {

    constructor(props: IProp) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div>


                <section style={{zIndex:-100}} className="hero-section" >
                        <div className="container" >
                            <div className="row" >
                                <div id="particles-js" />
                                <div style={{width: "100%", margin: "0 auto", fontFamily: "Stencil"}} className="col-md-5 hero-text">
                                    <h2 >We provide <span>Top Tier</span> Software Engineers</h2>
                                    <h2 >For your business</h2>

                                    {/*<form className="hero-subscribe-from">*/}
                                    {/*    <input type="text" placeholder="Enter your email"/>*/}
                                    {/*    <button style={{zIndex: 1000,}} className="site-btn sb-gradients">Get Started</button>*/}
                                    {/*</form>*/}
                                </div>
                                <div style={{zIndex:-98}} className="col-md-7"  >
                                    {
                                        this.props.activeProp === 1
                                        ?
                                            <img  src={handshake} style={{bottom: "20%"}} className="app-image" alt=""/>
                                            :
                                            <img  src={tech} className="app-image" alt=""/>
                                    }
                                </div>
                            </div>
                        </div>

                </section>
            </div>
        );
    }
}

export default Hero;