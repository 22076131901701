import React, {Component, RefObject} from 'react';
import Interview from './img/interview.png';
import Code from './img/coding.png';
import CodeInt from './img/coding-int.png';
import Handshake from './img/handshake.png';

interface IProp {
    appRefProp: RefObject<HTMLDivElement>;
}

class Process extends Component<IProp> {

    constructor(props: IProp) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div ref={this.props.appRefProp}>

                <section className="process-section spad">
                    <div style={{marginLeft: "50px"}}>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">

                                <div className="section-title text-center">
                                    <h2>Hiring Process</h2>
                                    <p></p>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 process">
                                        <div className="process-step">
                                            <figure className="process-icon">
                                                <img src={Interview} style={{width: 60}} alt="#"/>
                                            </figure>
                                            <h4>First Interview</h4>
                                            <p>This interview is to see if the candidate is culturally and technically aligned with our values.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 process">
                                        <div className="process-step">
                                            <figure className="process-icon">
                                                <img src={Code} style={{width: 60}} alt="#"/>
                                            </figure>
                                            <h4>Assignment</h4>
                                            <p>If the candidate is accepted in the first interview, they will immediately receive an assignment to work on.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 process">
                                        <div className="process-step">
                                            <figure className="process-icon">

                                                <img src={CodeInt} style={{width: 70}} alt="#"/>
                                            </figure>
                                            <h4>Assignment Technical Interview</h4>
                                            <p>During this interview the candidate will be challenged on their assignment solution.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 process">
                                        <div className="process-step">
                                            <figure className="process-icon">
                                                <img src={Handshake} style={{width: 60}} alt="#"/>
                                            </figure>
                                            <h4>Last Interview with Offer</h4>
                                            <p>If everything goes well, the candidate will have the last interview with an offer.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xl-4">

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Process;