
import React, {Component, RefObject} from 'react';

interface IProp {
    comingSoonRefProp: RefObject<HTMLDivElement>;
}

class Careers extends Component<IProp> {

    constructor(props: IProp) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div ref={this.props.comingSoonRefProp}>
                <section className="newsletter-section gradient-bg">
                    <div className="container text-white">
                        <div className="row">
                            <div className="col-lg-12 newsletter-text">
                                <h2>Positions</h2>
                                {/*<p>Sign up for our weekly industry updates, insider perspectives and in-depth market*/}
                                {/*    analysis.</p>*/}
                            </div>
                        </div>
<br/><br/>
                        <div className="row">
                            <div className="col-lg-12 newsletter-text">
                                <h5>Here at Raytek we primarily value candidates of their skills, regardless of their years of working experience. If you think you are up for the challenge send us your CV through:</h5>
                                {/*<p>Sign up for our weekly industry updates, insider perspectives and in-depth market*/}
                                {/*    analysis.</p>*/}
                            </div>
                        </div>
<br/>
                        <div className="row">
                            <div className="col-lg-12 newsletter-text">
                                <h5>hr@raytekconsultancy.com</h5>
                                {/*<p>Sign up for our weekly industry updates, insider perspectives and in-depth market*/}
                                {/*    analysis.</p>*/}
                            </div>
                        </div>
                    </div>
                    {/*        <div className="container cta-100 ">*/}
                    {/*            <div className="container">*/}
                    {/*                <div className="row blog">*/}
                    {/*                    <div className="col-md-12">*/}
                    {/*                        <div id="blogCarousel" className="carousel slide container-blog"*/}
                    {/*                             data-ride="carousel">*/}
                    {/*                            <ol className="carousel-indicators">*/}
                    {/*                                <li data-target="#blogCarousel" data-slide-to="0"*/}
                    {/*                                    className="active"></li>*/}
                    {/*                                <li data-target="#blogCarousel" data-slide-to="1"></li>*/}
                    {/*                            </ol>*/}
                    {/*                            <div className="carousel-inner">*/}
                    {/*                                <div className="carousel-item active">*/}
                    {/*                                    <div className="row">*/}
                    {/*                                        <div className="col-md-4">*/}
                    {/*                                            <div className="item-box-blog">*/}
                    {/*                                                <div className="item-box-blog-image">*/}
                    {/*                                                    <div*/}
                    {/*                                                        className="item-box-blog-date bg-blue-ui white">*/}
                    {/*                                                        <span className="mon">Augu 01</span></div>*/}
                    {/*                                                    <figure><img alt=""*/}
                    {/*                                                                 src="https://cdn.pixabay.com/photo/2017/02/08/14/25/computer-2048983_960_720.jpg"/>*/}
                    {/*                                                    </figure>*/}
                    {/*                                                </div>*/}
                    {/*                                                <div className="item-box-blog-body">*/}
                    {/*                                                    <div className="item-box-blog-heading">*/}
                    {/*                                                        <a href="#" tabIndex={0}>*/}
                    {/*                                                            <h5>News Title</h5>*/}
                    {/*                                                        </a>*/}
                    {/*                                                    </div>*/}
                    {/*                                                    <div className="item-box-blog-data"*/}
                    {/*                                                         style={{padding: "15px"}}>*/}
                    {/*                                                        <p><i*/}
                    {/*                                                            className="fa fa-user-o"></i> Admin, <i*/}
                    {/*                                                            className="fa fa-comments-o"></i> Comments(3)*/}
                    {/*                                                        </p>*/}
                    {/*                                                    </div>*/}
                    {/*                                                    <div className="item-box-blog-text">*/}
                    {/*                                                        <p>Lorem ipsum dolor sit amet, adipiscing.*/}
                    {/*                                                            Lorem ipsum dolor sit amet, consectetuer*/}
                    {/*                                                            adipiscing. Lorem ipsum dolor sit amet,*/}
                    {/*                                                            adipiscing. Lorem ipsum dolor sit amet,*/}
                    {/*                                                            adipiscing. Lorem ipsum dolor sit amet,*/}
                    {/*                                                            consectetuer adipiscing. Lorem ipsum*/}
                    {/*                                                            dolor.</p>*/}
                    {/*                                                    </div>*/}
                    {/*                                                    <div className="mt"><a href="#" tabIndex={0}*/}
                    {/*                                                                           className="btn bg-blue-ui white read">read*/}
                    {/*                                                        more</a></div>*/}
                    {/*                                                </div>*/}
                    {/*                                            </div>*/}
                    {/*                                        </div>*/}
                    {/*                                        */}
                    {/*                                        */}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="carousel-item ">*/}
                    {/*                                    <div className="row">*/}
                    {/*                                        <div className="col-md-4">*/}
                    {/*                                            <div className="item-box-blog">*/}
                    {/*                                                <div className="item-box-blog-image">*/}
                    {/*                                                    <div*/}
                    {/*                                                        className="item-box-blog-date bg-blue-ui white">*/}
                    {/*                                                        <span className="mon">Augu 01</span></div>*/}
                    {/*                                                    <figure><img alt=""*/}
                    {/*                                                                 src="https://cdn.pixabay.com/photo/2017/02/08/14/25/computer-2048983_960_720.jpg"/>*/}
                    {/*                                                    </figure>*/}
                    {/*                                                </div>*/}
                    {/*                                                <div className="item-box-blog-body">*/}
                    {/*                                                    <div className="item-box-blog-heading">*/}
                    {/*                                                        <a href="#" tabIndex={0}>*/}
                    {/*                                                            <h5>News Title</h5>*/}
                    {/*                                                        </a>*/}
                    {/*                                                    </div>*/}
                    {/*                                                    <div className="item-box-blog-data"*/}
                    {/*                                                         style={{padding: "15px"}}>*/}
                    {/*                                                        <p><i*/}
                    {/*                                                            className="fa fa-user-o"></i> Admin, <i*/}
                    {/*                                                            className="fa fa-comments-o"></i> Comments(3)*/}
                    {/*                                                        </p>*/}
                    {/*                                                    </div>*/}
                    {/*                                                    <div className="item-box-blog-text">*/}
                    {/*                                                        <p>Lorem ipsum dolor sit amet, adipiscing.*/}
                    {/*                                                            Lorem ipsum dolor sit amet, consectetuer*/}
                    {/*                                                            adipiscing. Lorem ipsum dolor sit amet,*/}
                    {/*                                                            adipiscing. Lorem ipsum dolor sit amet,*/}
                    {/*                                                            adipiscing. Lorem ipsum dolor sit amet,*/}
                    {/*                                                            consectetuer adipiscing. Lorem ipsum*/}
                    {/*                                                            dolor.</p>*/}
                    {/*                                                    </div>*/}
                    {/*                                                    <div className="mt"><a href="#" tabIndex={0}*/}
                    {/*                                                                           className="btn bg-blue-ui white read">read*/}
                    {/*                                                        more</a></div>*/}
                    {/*                                                </div>*/}
                    {/*                                            </div>*/}
                    {/*                                        </div>*/}
                    {/*                                        */}
                    {/*                                        */}
                    {/*                                        */}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </section>
            </div>
        );
    }
}

export default Careers;