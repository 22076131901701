import React, {Component, RefObject} from 'react';
import devops from "./img/devops.png"
import java from "./img/Java.png";
import ai from "./img/ai.png"
import project from "./img/project.png"

interface IProp {
    appRefProp: RefObject<HTMLDivElement>;
}

class RaytekServices extends Component<IProp> {

    constructor(props: IProp) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div ref={this.props.appRefProp} style={{backgroundColor: "white"}}>

                <div className="section_our_solution">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="our_solution_category">
                                <div className="solution_cards_box">
                                    <div className="solution_card">
                                        <div className="hover_color_bubble"></div>
                                        <div className="so_top_icon">

                                            <img  src={project} className="app-image" alt=""/>
                                        </div>
                                        <div className="solu_title">
                                            <h3>Project Solutions</h3>
                                        </div>
                                        <div className="solu_description">
                                            <p>
                                                Here at Raytek, we offer optimal project solutions where we provide clients with a squad of elite engineers. Our business model is to come up with an optimal solution for the client as fast as possible, and this is applicable because we have access to some of the best engineers around the world through our connections with the best universities in each country.
                                            </p>
                                            {/*<button type="button" className="read_more_btn">Read More</button>*/}
                                        </div>
                                    </div>
                                    <div className="solution_card">
                                        <div className="hover_color_bubble"></div>
                                        <div className="so_top_icon">
                                            <img  src={ai} className="app-image" alt=""/>
                                        </div>
                                        <div className="solu_title">
                                            <h3>Machine Learning, Deep Learning, and AI</h3>
                                        </div>
                                        <div className="solu_description">
                                            <p>
                                                At Raytek, we have AI engineers with strong experience in Machine Learning, Deep Learning, and Deep reinforcement learning. Furthermore, our data scientists and data miners excel in assisting  clients with understanding, extracting, cleaning and manipulating their valuable business data.
                                            </p>
                                            {/*<button type="button" className="read_more_btn">Read More</button>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="solution_cards_box sol_card_top_3">
                                    <div className="solution_card">
                                        <div className="hover_color_bubble"></div>
                                        <div className="so_top_icon">
                                            <img  src={java} className="app-image" alt=""/>
                                        </div>
                                        <div className="solu_title">
                                            <h3>Software Engineers & Developers</h3>
                                        </div>
                                        <div className="solu_description">
                                            <p>
                                                Our Software Engineers and Developers provide knowledge in wide range of programming languages, frameworks and valuable expertise that is crucial for bringing optimal design and clean code. Our connection to best universities in countries would make it possible for us to access the best engineers which we could provide to clients.
                                            </p>
                                            {/*<button type="button" className="read_more_btn">Read More</button>*/}
                                        </div>
                                    </div>
                                    <div className="solution_card">
                                        <div className="hover_color_bubble"></div>
                                        <div className="so_top_icon">
                                            <img  src={devops} className="app-image" alt=""/>
                                        </div>
                                        <div className="solu_title">
                                            <h3>Devops & QA</h3>
                                        </div>
                                        <div className="solu_description">
                                            <p>
                                                Our Devops and QAs can guide clients in having their projects Devops and tests go as smooth as possible. Our engineers' expertise will guarantee tested applications and robust releases to production.
                                            </p>
                                            {/*<button type="button" className="read_more_btn">Read More</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default RaytekServices;