import React, {Component, RefObject} from 'react';

import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import About from './About';
import Features from "./Features";
import Careers from "./Careers";
import Process from "./Process";
import Team from "./Team";
import RaytekServices from "./RaytekServices";


interface IProp {

}

interface IState {
    active: Number;
}

class Home extends Component<IProp, IState> {

    private topRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private aboutRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private featuresRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private comingSoonRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private appRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private teamRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private contactRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    constructor(props: IProp) {
        super(props);
        this.state = {
            active: 0
        }
    }

    private rollUp = () => {
        this.topRef.current.scrollIntoView({behavior: "smooth"});
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    public handleScroll = (event: any) => {
        const {pageYOffset} = window;
        var active = this.state;

        if (pageYOffset >= 1000 && this.state.active === 0) {
            this.setState({active: 1});
        } else if (pageYOffset < 1000 && this.state.active === 1) {
            this.setState({active: 0});
        }
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div ref={this.topRef}>
                {/*Page Preloder */}
                <div id="preloder">
                    <div className="loader"></div>
                </div>


                {/*<Header/>*/}
                <Header aboutRefProp={this.aboutRef} comingSoonRefProp={this.comingSoonRef}
                        featuresRefProp={this.featuresRef} appRefProp={this.appRef} teamRefProp={this.teamRef} contactRefProp={this.contactRef}/>

                {/* Hero section */}
                <Hero activeProp={this.state.active}/>
                {/* Hero section end */}

                {/* About section */}
                <About aboutRefProp={this.aboutRef} featuresRefProp={this.featuresRef}/>
                {/* About section end */}


                {/* Features section */}
                <Features featuresRefProp={this.featuresRef}/>
                {/* Features section end */}


                {/* Process section */}
                <RaytekServices appRefProp={this.appRef}/>
                {/* Process section end */}


                {/* Newsletter section */}
                <Careers comingSoonRefProp={this.comingSoonRef}/>
                {/* Newsletter section end */}

                {/* Process section */}
                <Process appRefProp={this.appRef}/>
                {/* Process section end */}

                {/* Fact section */}
                <section className="fact-section gradient-bg">
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-sm-6 col-md-6 col-lg-4">*/}
                            {/*    <div className="fact">*/}
                            {/*        <h2>20</h2>*/}
                            {/*        <p>Clients</p>*/}
                            {/*        <i className="ti-basketball"></i>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-6 col-md-6 col-lg-4">*/}
                            {/*    <div className="fact">*/}
                            {/*        <h2>5+</h2>*/}
                            {/*        <p>Projects</p>*/}
                            {/*        <i className="ti-panel"></i>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*/!*<div className="col-sm-6 col-md-6 col-lg-3">*!/*/}
                            {/*/!*    <div className="fact">*!/*/}
                            {/*/!*        <h2>100%</h2>*!/*/}
                            {/*/!*        <p>Largest <br/> Transactions</p>*!/*/}
                            {/*/!*        <i className="ti-stats-up"></i>*!/*/}
                            {/*/!*    </div>*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*<div className="col-sm-6 col-md-6 col-lg-4">*/}
                            {/*    <div className="fact">*/}
                            {/*        <h2>10+</h2>*/}
                            {/*        <p>Employees</p>*/}
                            {/*        <i className="ti-user"></i>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </section>
                {/* Fact section end */}


                <Team teamRefProp={this.teamRef}/>


                {/* Review section */}
                {/*<section className="review-section spad">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-8 push-8">*/}
                {/*                /!*<img src="img/quote.png" alt="" className="quote mb-5"/>*!/*/}
                {/*                <div className="review-text-slider owl-carousel">*/}
                {/*                    <div className="review-text">*/}
                {/*                        <p>"Bitcoin is exciting because it shows how cheap it can be. Bitcoin is better*/}
                {/*                            than currency in that you don’t have to be physically in the same place and,*/}
                {/*                            of course, for large transactions, currency can get pretty*/}
                {/*                            inconvenient.”</p>*/}
                {/*                    </div>*/}
                {/*                    <div className="review-text">*/}
                {/*                        <p>"Bitcoin is exciting because it shows how cheap it can be. Bitcoin is better*/}
                {/*                            than currency in that you don’t have to be physically in the same place and,*/}
                {/*                            of course, for large transactions, currency can get pretty*/}
                {/*                            inconvenient.”</p>*/}
                {/*                    </div>*/}
                {/*                    <div className="review-text">*/}
                {/*                        <p>"Bitcoin is exciting because it shows how cheap it can be. Bitcoin is better*/}
                {/*                            than currency in that you don’t have to be physically in the same place and,*/}
                {/*                            of course, for large transactions, currency can get pretty*/}
                {/*                            inconvenient.”</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-lg-4 pr-0 pull-3">*/}
                {/*                <div className="review-meta-slider owl-carousel pt-5">*/}
                {/*                    <div className="author-meta">*/}
                {/*                        <div className="author-avatar set-bg" data-setbg="img/review/1.jpg"></div>*/}
                {/*                        <div className="author-name">*/}
                {/*                            <h4>Aaron Ballance</h4>*/}
                {/*                            <p>Ceo Bitcoin</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="author-meta">*/}
                {/*                        <div className="author-avatar set-bg" data-setbg="img/review/2.jpg"></div>*/}
                {/*                        <div className="author-name">*/}
                {/*                            <h4>Jackson Nash</h4>*/}
                {/*                            <p>Head of Design</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="author-meta">*/}
                {/*                        <div className="author-avatar set-bg" data-setbg="img/review/3.jpg"></div>*/}
                {/*                        <div className="author-name">*/}
                {/*                            <h4>Katy Abrams</h4>*/}
                {/*                            <p>Product Manager</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*/!* Review section end *!/*/}


                {/* Blog section*/}
                {/*<section className="blog-section spad">*/}
                {/*    <div className="container">*/}
                {/*        <div className="section-title text-center">*/}
                {/*            <h2>Latest News</h2>*/}
                {/*            <p>Bitcoin is the simplest way to exchange money at very low cost.</p>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}
                {/*            /!* blog item *!/*/}
                {/*            <div className="col-md-4">*/}
                {/*                <div className="blog-item">*/}
                {/*                    <figure className="blog-thumb">*/}
                {/*                        <img src="img/blog/1.jpg" alt=""/>*/}
                {/*                    </figure>*/}
                {/*                    <div className="blog-text">*/}
                {/*                        <div className="post-date">03 jan 2018</div>*/}
                {/*                        <h4 className="blog-title"><a href="">Coinbase to Reopen the GDAX Bitcoin*/}
                {/*                            Cash-Euro Order Book</a></h4>*/}
                {/*                        <div className="post-meta">*/}
                {/*                            <a href=""><span>by</span> Admin</a>*/}
                {/*                            <a href=""><i className="fa fa-heart-o"></i> 234 Likes</a>*/}
                {/*                            <a href=""><i className="fa fa-comments-o"></i> 08 comments</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            /!* blog item *!/*/}
                {/*            <div className="col-md-4">*/}
                {/*                <div className="blog-item">*/}
                {/*                    <figure className="blog-thumb">*/}
                {/*                        <img src="img/blog/2.jpg" alt=""/>*/}
                {/*                    </figure>*/}
                {/*                    <div className="blog-text">*/}
                {/*                        <div className="post-date">28 dec 2018</div>*/}
                {/*                        <h4 className="blog-title"><a href="">Blockchain Rolls Out Trading Feature for*/}
                {/*                            22 States in the U.S</a></h4>*/}
                {/*                        <div className="post-meta">*/}
                {/*                            <a href=""><span>by</span> Admin</a>*/}
                {/*                            <a href=""><i className="fa fa-heart-o"></i> 234 Likes</a>*/}
                {/*                            <a href=""><i className="fa fa-comments-o"></i> 08 comments</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            /!* blog item *!/*/}
                {/*            <div className="col-md-4">*/}
                {/*                <div className="blog-item">*/}
                {/*                    <figure className="blog-thumb">*/}
                {/*                        <img src="img/blog/3.jpg" alt=""/>*/}
                {/*                    </figure>*/}
                {/*                    <div className="blog-text">*/}
                {/*                        <div className="post-date">28 aug 2018</div>*/}
                {/*                        <h4 className="blog-title"><a href="">This Week in Bitcoin: Up, Down and*/}
                {/*                            Sideways</a></h4>*/}
                {/*                        <div className="post-meta">*/}
                {/*                            <a href=""><span>by</span> Admin</a>*/}
                {/*                            <a href=""><i className="fa fa-heart-o"></i> 234 Likes</a>*/}
                {/*                            <a href=""><i className="fa fa-comments-o"></i> 08 comments</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/* Blog section end */}


                {/* Footer section */}
                <Footer  aboutRefProp={this.aboutRef} comingSoonRefProp={this.comingSoonRef}
                         featuresRefProp={this.featuresRef} appRefProp={this.appRef} teamRefProp={this.teamRef} contactRefProp={this.contactRef}/>

                {
                    this.state.active === 1
                        ?
                        <div style={{
                            height: 30,
                            width: 30,
                            position: "fixed",
                            bottom: 10,
                            left: 10,
                            border: "1px solid #000",
                            borderRadius: "50%",
                            cursor: "pointer"
                        }}>
                            <a onClick={this.rollUp}><i className="ti-angle-up"/></a>
                        </div>
                        :
                        <div/>
                }

            </div>
        );
    }
}

export default Home;