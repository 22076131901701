import React, {Component, Ref, RefObject} from 'react';
import Logo from './img/logo29.png';

interface IProps {
    aboutRefProp: RefObject<HTMLDivElement>;
    comingSoonRefProp: RefObject<HTMLDivElement>;
    featuresRefProp: RefObject<HTMLDivElement>;
    appRefProp: RefObject<HTMLDivElement>;
    teamRefProp: RefObject<HTMLDivElement>;
    contactRefProp: RefObject<HTMLDivElement>;
}

interface IState {
    mobile: Boolean;
    menuOpen: Boolean;
}

class Header extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        if (window.innerWidth < 768) {
            this.state = {
                mobile: true,
                menuOpen: false
            }
        } else {
            this.state = {
                mobile: false,
                menuOpen: false
            }
        }
    }

    public goToAbout = () => {
        this.props.aboutRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToFeatures = () => {
        this.props.featuresRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToNews = () => {
        this.props.comingSoonRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToApp = () => {
        this.props.appRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToTeam = () => {
        this.props.teamRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToContact = () => {
        this.props.contactRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    componentDidMount() {
        window.addEventListener("resize", this.screenResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.screenResize.bind(this));
    }

    screenResize = (event: any) => {
        if (window.innerWidth < 768) {
            this.setState({
                mobile: true
            });
        } else {
            this.setState({
                mobile: false
            });
        }

    }

    openMenu = () => {
        if (this.state.menuOpen === false) {
            this.setState({
                menuOpen: true
            })
        } else {
            this.setState({
                menuOpen: false
            })
        }
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                {/*Header section*/}
                <header className="header-section clearfix">
                    <div className="container-fluid">
                        <a href="index.html" className="site-logo">
                            <img style={{width: 80, height: 80}} className="logo" src={Logo} alt=""/>
                        </a>
                        <div className="responsive-bar"><a onClick={this.openMenu}><i className="fa fa-bars"></i></a>
                        </div>
                        {/*<div className="responsive-menu"></div>*/}

                        {/*<div>*/}
                        <a style={{cursor: "pointer", color: "white"}} onClick={this.goToNews} className="site-btn">Careers</a>
                        <nav className="main-menu">
                            {
                                this.state.mobile
                                    ?

                                    <div style={{
                                        height: 300,
                                        width: 200,
                                        position: "absolute",
                                        borderRadius: 5,
                                        top: 5,
                                        right: 5,
                                        backgroundColor: "white",
                                        zIndex: 100,
                                        display: this.state.menuOpen ? "block" : "none"
                                    }}>
                                        <ul style={{listStyle: "none", paddingTop: 20, paddingRight: 15,}}>
                                            <li><a onClick={this.goToAbout}
                                                   style={{ cursor: "pointer", color: "black"}}>About</a></li>
                                            <br/>
                                            <li><a onClick={this.goToFeatures}
                                                   style={{ cursor: "pointer", color: "black"}}>Raytek</a></li>
                                            <br/>
                                            <li><a onClick={this.goToApp}
                                                   style={{cursor: "pointer", color: "black"}}>Hiring Process</a></li>
                                            <br/>
                                            <li><a onClick={this.goToTeam}
                                                   style={{cursor: "pointer", color: "black"}}>Team</a></li>
                                            <br/>
                                            <li><a onClick={this.goToContact}
                                                   style={{cursor: "pointer", color: "black"}}>Contact</a></li>
                                            <br/>
                                        </ul>

                                    </div>

                                    :

                                    <div>

                                        <ul className="menu-list">
                                            <li><a onClick={this.goToAbout}
                                                   style={{ cursor: "pointer", color: "white"}}>About</a></li>
                                            <li><a onClick={this.goToFeatures}
                                                   style={{cursor: "pointer", color: "white"}}>Raytek</a></li>
                                            <li><a onClick={this.goToApp}
                                                   style={{cursor: "pointer", color: "white"}}>Hiring Process</a></li>
                                            <li><a onClick={this.goToTeam}
                                                   style={{cursor: "pointer", color: "white"}}>Team</a></li>
                                            <li><a onClick={this.goToContact}
                                                   style={{cursor: "pointer", color: "white"}}>Contact</a></li>
                                        </ul>
                                    </div>

                            }
                        </nav>
                        {/*</div>*/}

                    </div>
                </header>
                {/* Header section end */}
            </div>
        );
    }
}


export default Header;