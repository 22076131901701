import React, {Component, RefObject} from 'react';

import Work from './img/work.png';

interface IProps {
    readonly aboutRefProp: RefObject<HTMLDivElement>;
    readonly featuresRefProp: RefObject<HTMLDivElement>;
}

class About extends Component<IProps> {


    constructor(props: IProps) {
        super(props);
    }

    private moveToFeatures = () => {
        this.props.featuresRefProp.current.scrollIntoView({behavior: "smooth"})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (

            <div ref={this.props.aboutRefProp}>

                <section className="about-section spad" style={{zIndex: 1, backgroundColor: "#f3f7f9"}}>
                    <div className="container">
                        <div className="row wow fadeInRight" data-wow-duration="1.1s"
                             data-wow-delay=".2s">
                            <div className="col-lg-6 offset-lg-6 about-text">
                                <h2>What is Raytek</h2>
                                <h5>A tech consultancy and recruiting company</h5>
                                <p style={{textAlign: "left"}}> Here at Raytek, we provide top companies with top
                                    engineers. Fast and optimal, these are the keywords that define us. Raytek
                                    differentiates itself as a tech and consultancy company by only hiring engineers
                                    that graduated from the top three ranked universities from their respective
                                    countries. Because of our good connections with a selection of these universities
                                    and their graduates, we can fulfil a new request from clients for a candidate within
                                    a month. Our
                                    strict recruitment policy and candidate selection processes guarantee that every
                                    engineer will have a strong positive impact on your business.

                                    <br/><br/>
                                    At Raytek, we pride ourselves on our impeccable reliability and unprecedented
                                    velocity. Our team of top engineers is dedicated to providing clients with the best
                                    possible service whether it's through hiring or partaking in projects from the
                                    client. </p>
                                <a style={{cursor: "pointer"}} onClick={this.moveToFeatures}
                                   className="site-btn sb-gradients sbg-line mt-5">Why RayTek?</a>
                            </div>
                        </div>
                        <div className="about-img wow fadeInLeft" data-wow-duration="1.1s"
                             data-wow-delay=".2s">
                            <img src={Work} alt=""/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default About;