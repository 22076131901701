import React, {Component, RefObject} from 'react';

interface IProp {
    featuresRefProp: RefObject<HTMLDivElement>;
}

class Features extends Component<IProp> {

    constructor(props: IProp) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div ref={this.props.featuresRefProp}>

                <section className="features-section spad gradient-bg">
                    <div className="container text-white">
                        <div className="section-title text-center">
                            <h2>Why Raytek?</h2>
                            {/*<p>Bitcoin is the simplest way to exchange money at very low cost.</p>*/}
                        </div>
                        <div className="row">

                            {/* feature */}
                            <div className="col-md-6 col-lg-4 feature">
                                <i className="ti-control-forward"></i>
                                <div className="feature-content">
                                    <h4>Fast Hiring</h4>
                                    <p>Through our novel hiring approach, we are able to find out the best engineers within a short period of time and provide them to the clients.</p>
                                    {/*<a href="" className="readmore">Readmore</a>*/}
                                </div>
                            </div>
                            {/* feature */}
                            <div className="col-md-6 col-lg-4 feature">
                                <i className="ti-link"></i>
                                <div className="feature-content">
                                    <h4>Top Engineers</h4>
                                    <p>Here at Raytek, we have good connections with the top ranking universities of multiple countries. Therefore, we can guarantee that our engineers are of the highest seniority.</p>
                                    {/*<p>Raytek performs private protocol-level KYC for all blockchain networks*/}
                                    {/*    to regulates Decentralized Finance and protect it against money laundering,*/}
                                    {/*    terrorist financing, tax evasion, and fraud. </p>*/}
                                    {/*<a href="" className="readmore">Readmore</a>*/}
                                </div>
                            </div>
                            {/* feature */}
                            <div className="col-md-6 col-lg-4 feature">
                                <i className="ti-wallet"></i>
                                <div className="feature-content">
                                    <h4>Cost-Effective</h4>
                                    <p>We pride ourselves to be one of the most low-cost consultancy companies due to our choice of selection and business model</p>
                                    {/*<a href="" className="readmore">Readmore</a>*/}
                                </div>
                            </div>

                            {/*/!* feature *!/*/}
                            {/*<div className="col-md-6 col-lg-4 feature">*/}
                            {/*    <i className="ti-panel"></i>*/}
                            {/*    <div className="feature-content">*/}
                            {/*        <h4>Artificial Intelligence</h4>*/}
                            {/*        <p>Raytek uses AI for fraud detection. </p>*/}
                            {/*        /!*<a href="" className="readmore">Readmore</a>*!/*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*/!* feature *!/*/}
                            {/*<div className="col-md-6 col-lg-4 feature">*/}
                            {/*    <i className="ti-mobile"></i>*/}
                            {/*    <div className="feature-content">*/}
                            {/*        <h4>Easy To Use</h4>*/}
                            {/*        <p>Raytek App creates an easy to use user interface to facilitate fast*/}
                            {/*            payments</p>*/}
                            {/*        /!*<a href="" className="readmore">Readmore</a>*!/*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*/!* feature *!/*/}
                            {/*<div className="col-md-6 col-lg-4 feature">*/}
                            {/*    <i className="ti-reload"></i>*/}
                            {/*    <div className="feature-content">*/}
                            {/*        <h4>Instant Exchange</h4>*/}
                            {/*        <p>We have used microservice design using java programing language which results in fast and reliable transactions. </p>*/}
                            {/*        /!*<a href="" className="readmore">Readmore</a>*!/*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Features;