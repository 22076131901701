
import React, {Component, RefObject} from 'react';

interface IProp {
    aboutRefProp: RefObject<HTMLDivElement>;
    comingSoonRefProp: RefObject<HTMLDivElement>;
    featuresRefProp: RefObject<HTMLDivElement>;
    appRefProp: RefObject<HTMLDivElement>;
    teamRefProp: RefObject<HTMLDivElement>;
    contactRefProp: RefObject<HTMLDivElement>;
}

class Footer extends Component<IProp> {

    constructor(props: IProp) {
        super(props);
    }

    public goToAbout = () => {
        this.props.aboutRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToFeatures = () => {
        this.props.featuresRefProp.current.scrollIntoView({behavior: "smooth"});
    }


    public goToApp = () => {
        this.props.appRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToTeam = () => {
        this.props.teamRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    public goToContact = () => {
        this.props.contactRefProp.current.scrollIntoView({behavior: "smooth"});
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <div ref={this.props.contactRefProp}>
                <footer className="footer-section">
                    <div className="container">
                        <div className="row spad">
                            <div className="col-md-6 col-lg-3 footer-widget">
                                <img src="img/logo.png" className="mb-4" alt=""/>
                                <p>Based in Utrecht, Netherlands</p>
                                <p>KVK-number: 91510562</p>
                                <p>Email: hr@raytekconsultancy.com</p>
                                <p>Phone: +31 627 960 220</p>
                            </div>
                            {/*<div className="col-md-6 col-lg-2 offset-lg-1 footer-widget">*/}
                            {/*    <h5 className="widget-title">Resources</h5>*/}
                            {/*    <ul>*/}
                            {/*        <li><a href="#">How to Buy Coin</a></li>*/}
                            {/*        <li><a href="#">Coin Overview</a></li>*/}
                            {/*        <li><a href="#">Blog News</a></li>*/}
                            {/*        <li><a href="#">How to Sell Coin</a></li>*/}
                            {/*        <li><a href="#">Purchase Theme</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            <div className="col-md-6 col-lg-3 offset-lg-1 footer-widget">
                                <h5 className="widget-title">Quick Links</h5>
                                <ul className="widget-title">
                                    <li><a onClick={this.goToFeatures} style={{cursor: "pointer", color: "white"}} >Raytek</a></li>
                                    {/*<li><a href="">News</a></li>*/}
                                    <li><a onClick={this.goToAbout} style={{cursor: "pointer", color: "white"}}>About</a></li>
                                    <li><a onClick={this.goToApp} style={{cursor: "pointer", color: "white"}}>Hiring Process</a></li>
                                    <li><a onClick={this.goToTeam} style={{cursor: "pointer", color: "white"}}>Team</a></li>
                                    <li><a onClick={this.goToContact } style={{cursor: "pointer", color: "white"}}>Contact</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-5 footer-widget pl-lg-5 pl-3">
                                <h5 className="widget-title">Follow Us</h5>
                                <div className="social">
                                    <a href="https://www.linkedin.com/company/raytekconsultancy/" className="facebook"><i className="fa fa-linkedin"></i></a>
                                    {/*<a href="" className="google"><i className="fa fa-google-plus"></i></a>*/}
                                    {/*<a href="" className="instagram"><i className="fa fa-instagram"></i></a>*/}
                                    {/*<a href="" className="twitter"><i className="fa fa-twitter"></i></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="row">
                                <div className="col-lg-4 store-links text-center text-lg-left pb-3 pb-lg-0">
                                    <a href=""><img src="img/appstore.png" alt="" className="mr-2"/></a>
                                    <a href=""><img src="img/playstore.png" alt=""/></a>
                                </div>
                                <div className="col-lg-8 text-center text-lg-right">
                                     <span>{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                                         Copyright &copy; Raytek
                                    | 2023 | All rights reserved
                                         {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>


            </div>
        );
    }
}

export default Footer;